import classNames from "classnames";
import "./index.scss";
import { formatDateFrom } from "../../../utils";
import ReleaseLink from "../ReleaseLink/ReleaseLink";

const TokenPriceHistoryTable = ({ data }) => {
  const priceClass = classNames({
    table: true,
  });

  if (!data || data.length === 0) return null;

  return (
    <article className={priceClass}>
      <div className="table__col">
        <span className="table__key">Выпуск</span>
        {data.map((activeRelease) => (
          <span
            className="table__value"
            key={`table-price-history-${activeRelease.id}`}
          >
            <ReleaseLink release={activeRelease} />
          </span>
        ))}
      </div>

      <div className="table__col">
        <span className="table__key">Количество</span>
        {data.map((release) => (
          <span key={release.id} className="table__value">
            {release.totalTokens > 0 ? `${release.totalTokens} ЦФА` : `-`}
          </span>
        ))}
      </div>

      <div className="table__col">
        <span className="table__key">Цена за 100 ЦФА</span>
        {data.map((release) => (
          <span key={release.id} className="table__value">
            {release.price.toLocaleString()} ₽
          </span>
        ))}
      </div>

      <div className="table__col">
        <span className="table__key">Дата</span>
        {data.map((release) => (
          <span key={release.id} className="table__value">
            {formatDateFrom(release.plannedStartDate, true)}
          </span>
        ))}
      </div>
    </article>
  );
};

export default TokenPriceHistoryTable;
