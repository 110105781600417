import classNames from "classnames";
import Button from "../../../ui/Button/Button";
import Label from "../../../ui/Label/Label";
import Collapse from "../../../ui/Collapse/Collapse";
import { Fragment, useState, useMemo } from "react";
import CollapseToggler from "../../../ui/Collapse/CollapseToggler";
import { formatId, numWord } from "../../../../utils";
import { RepaymentMethods } from "../../../../constants";
import { formatDateFrom } from "../../../../utils";
import "moment/locale/ru";
import ReleaseLink from "../../../ui/ReleaseLink/ReleaseLink";
import PoolLabel from "../../../ui/PoolLabel/PoolLabel";
import StatusLabel from "../../../ui/Card/StatusLabel";
import { formatTokensForDisplay } from "../../../../utils";
import useMobile from "../../../../hooks/useMobile";
import PurchaseCard from "../../../ui/PurchaseCard/PurchaseCard";
import ConfirmationCard from "./ConfirmationCard";
const CardHorizontalToken = ({ tokenData }) => {
  const [collapseOpened, setCollapseOpened] = useState(false);
  const [confirmCardOpened, setConfirmCardOpened] = useState(false);
  const [releaseForConfirm, setReleaseForConfirm] = useState(null);
  const isMobile = useMobile();
  const cardClass = classNames({
    card: true,
    "card_h card_token": !isMobile,
    "card_collapse-opened": collapseOpened,
  });

  const sortedOperations = useMemo(() => {
    if (tokenData) {
      // Собираем все операции из релизов в один массив, добавляем релиз в объект для вывода информации
      const operations = tokenData.releases.flatMap((release) =>
        release.operations.map((op) => ({ ...op, release }))
      );

      return operations.sort((a, b) => new Date(b.date) - new Date(a.date));
    }

    return null;
  }, [tokenData]);

  if (!tokenData) return null;

  const { releases, pool, building, developer, emitter } = tokenData;
  const availableTokens = formatTokensForDisplay(tokenData.amount);

  const templatesOperationTransferOrder = {
    INIT: "купили",
    SELL: "продали",
    BUY: "купили",
    TRANSFER_IN: "получили",
    TRANSFER_OUT: "передали",
  };

  if (!building) return null;

  return (
    <article className={cardClass}>
      <div className="card__wrapper">
        <div className="card__labels-list">
          <StatusLabel data={tokenData} />
          <Label labelType="secondary">100 ЦФА = стоимости 1 м²</Label>
          {pool && pool.repaymentMethod && (
            <Label labelType="secondary">
              {RepaymentMethods[pool.repaymentMethod]}
            </Label>
          )}
        </div>

        <PoolLabel addClass="card__pool-label">
          {pool.name || formatId(pool.id)}
        </PoolLabel>
        <img className="card__img" src={building?.imagesLinks[0]} alt="" />
      </div>
      <div className="card__body">
        {/* <div className="card__labels-list">
          <Label labelType="tertiary">Построено 25%</Label>
        </div> */}

        <div className="sell-info">
          <div className="sell-info__col">
            <span className="sell-info__title">О жилом комплексе</span>
            <span className="sell-info__key">{building.name}</span>
            <p className="sell-info__value">{developer.name}</p>

            <span className="sell-info__key">{building.city}</span>
            <p className="sell-info__value">{building.district}</p>

            {emitter && (
              <>
                <span className="sell-info__key">{emitter.name}</span>
                <p className="sell-info__value">Эмитент</p>
              </>
            )}
          </div>

          <div className="sell-info__col">
            <span className="sell-info__title">Количество</span>
            <span className="sell-info__key">{availableTokens} ЦФА</span>
            <p className="sell-info__value">Общее кол-во ЦФА</p>
            <span className="sell-info__key">{availableTokens / 100} м²</span>
            <p className="sell-info__value" style={{ whiteSpace: "nowrap" }}>
              {numWord(availableTokens / 100, [
                "токенизированный",
                "токенизированных",
                "токенизированных",
              ])}{" "}
              м²
            </p>
            {releases.map((release, i) => (
              <span
                key={`my-token-release-${release.id}`}
                className={`sell-info__key${i === 0 ? " with-separator" : ""}`}
              >
                {
                  <ReleaseLink
                    ellipsis
                    release={release}
                    needToConfirm={() => {
                      setConfirmCardOpened(!confirmCardOpened);
                      setReleaseForConfirm(release);
                    }}
                  />
                }
                <p className="sell-info__value">
                  {formatTokensForDisplay(release.amount)}
                </p>
              </span>
            ))}
          </div>

          {pool.repaymentMethod === "GUARANTEED_INCOME" && (
            <div className="sell-info__col">
              <span className="sell-info__title">Фиксированный доход</span>
              <span className="sell-info__key">{pool.exitPrice} ₽</span>
              <p className="sell-info__value">
                Со 100 ЦФА после ввода в эксплуатацию
              </p>
            </div>
          )}

          {pool.repaymentMethod === "SALES_INCOME" && (
            <div className="sell-info__col">
              <span className="sell-info__title">Доход от продаж</span>
              <span className="sell-info__key">
                {pool.exitPrice} ₽ + % от продажи
              </span>
              <p className="sell-info__value">Фиксированный доход</p>
            </div>
          )}
        </div>

        <div className="card__operations">
          <CollapseToggler
            addClass={"card__collapse-btn"}
            collapseOpened={collapseOpened}
            setCollapseOpened={setCollapseOpened}
          >
            Операции
          </CollapseToggler>
          <div className="card__btns">
            <Button
              size={"xs"}
              addClass="card__buy-btn"
              // onClick={() => setPurchaseCardOpened(true)}
              isLink={`/${pool.id}`}
            >
              К странице пула
            </Button>
            {/* <Button
              size={"xs"}
              addClass="card__buy-btn"
              onClick={() => setPurchaseCardSellOpened(true)}
            >
              Продать
            </Button> */}
          </div>
        </div>

        <Collapse isOpened={collapseOpened} addClass="card__collapse">
          <div className="sell-info sell-info_single">
            <div className="sell-info__col">
              {sortedOperations?.map((op) => {
                const isTransferType = ["TRANSFER_IN", "TRANSFER_OUT"].includes(
                  op.orderType
                );
                if (op.orderType === "BURNING") {
                  return (
                    <Fragment key={op.id}>
                      <span className="sell-info__key">
                        Выпуск {<ReleaseLink release={op.release} noIcon />}{" "}
                        завершен. Вам начислены средства за ЦФА. По возникшим
                        вопросам писать на почту -{" "}
                        <a
                          className="link link_blue"
                          href="mailto:support@1meter.ru"
                        >
                          support@1meter.ru
                        </a>{" "}
                      </span>
                      <p className="sell-info__value">
                        {formatDateFrom(op.date)}
                      </p>
                    </Fragment>
                  );
                }
                return (
                  <Fragment key={op?.id}>
                    <span className="sell-info__key">
                      Вы{" "}
                      {templatesOperationTransferOrder[op.orderType] ??
                        "произвели операцию с"}{" "}
                      {formatTokensForDisplay(op.amount)} ЦФА {building.name} в
                      выпуске {<ReleaseLink release={op.release} noIcon />}
                      {!isTransferType && (
                        <>
                          <span> за </span>
                          <span style={{ whiteSpace: "nowrap" }}>
                            {op.price} ₽
                          </span>
                        </>
                      )}
                    </span>
                    <p className="sell-info__value">
                      {formatDateFrom(op.date)}
                    </p>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </Collapse>
      </div>

      {releaseForConfirm && (
        <ConfirmationCard
          releaseForDisplay={releaseForConfirm}
          isOpened={confirmCardOpened}
          setIsOpened={setConfirmCardOpened}
        />
      )}
    </article>
  );
};

export default CardHorizontalToken;
