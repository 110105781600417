import { QRCodeSVG } from "qrcode.react";
import { useQrCodeOrder } from "../../../hooks/fetchers/orders";
import { Loader } from "../Loader/Loader";
import {
  downloadFile,
  findElemForTemplateDetailsPayment,
} from "../../../utils";

const Qr = ({ orderId }) => {
  const { data: qrcode, isLoading: qrCodeIsLoading } = useQrCodeOrder(orderId);

  if (qrCodeIsLoading) return <Loader isBlue isLarge />;

  if (!qrcode) return null;

  const parseDataQrcode = qrcode.split("|") ?? [];

  const templateDetailsPayment = `Реквизиты для оплаты: 

Получатель платежа: ${findElemForTemplateDetailsPayment(parseDataQrcode, "Name")}
ИНН получателя: ${findElemForTemplateDetailsPayment(parseDataQrcode, "PayeeINN")}
Назначение платежа: ${findElemForTemplateDetailsPayment(parseDataQrcode, "purpose")}
Наименование банка: ${findElemForTemplateDetailsPayment(parseDataQrcode, "BankName")}
ИНН Банка: ${findElemForTemplateDetailsPayment(parseDataQrcode, "PayeeINN")}
КПП Банка: ${findElemForTemplateDetailsPayment(parseDataQrcode, "KPP")}
БИК: ${findElemForTemplateDetailsPayment(parseDataQrcode, "BIC")}
Расчетный счет: ${findElemForTemplateDetailsPayment(parseDataQrcode, "PersonalAcc")}
Корреспондентский счет: ${findElemForTemplateDetailsPayment(parseDataQrcode, "CorrespAcc")}`;

  return (
    <div className="qr-wrapper">
      <div className="qr">
        <QRCodeSVG value={qrcode} />
      </div>
      <p>
        или по реквизитам{" "}
        <span
          onClick={() =>
            downloadFile(
              new Blob([templateDetailsPayment]),
              "Банковские реквизиты.txt",
            )
          }
          className="a"
        >
          счета на оплату
        </span>
      </p>
    </div>
  );
};

export default Qr;
