import moment from "moment";
import { createDetachedSignature, createHash } from "crypto-pro";

// Convert file to base64 string
export const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = (event) => {
      event.target && event.target.result && resolve(event.target.result);
    };

    // Convert data to base64

    if (!!file) {
      reader.readAsDataURL(file);
    }
  });
};

/** @type {(id : string) => string} */
export const formatId = (id = "") => {
  if (!id) return "";
  // if (id.length > 6) return id.slice(-6);
  return id;
};

export const numWord = (value, words) => {
  value = Math.abs(value) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
};
// Чтобы заполнить массив с формами склонений, нужно ответить на три вопроса:

// Каким будет слово для одной единицы?
// Каким будет слово для двух единиц?
// Каким будет слово для пяти единиц?
// Примеры заполненных массивов:
// num_word(value, ['товар', 'товара', 'товаров']);
// num_word(value, ['штука', 'штуки', 'штук']);
// num_word(value, ['пара', 'пары', 'пар']);
// num_word(value, ['рубль', 'рубля', 'рублей']);

// Расчет суммарной площади квартир
export const sumFootage = (flatsList = []) => {
  if (!flatsList || flatsList.length === 0) return "0 м²";
  if (flatsList.length === 1) return `${flatsList[0]?.footage} м²`;
  return `${flatsList.reduce((acc, it) => acc + it?.footage, 0)} м²`;
};

/**
 * Returns object with fields from object
 * @param {Object} object
 * @param {string[]} fields
 */
export const extractFields = (object, fields) => {
  return fields.reduce((acc, field) => {
    acc[field] = object[field];
    return acc;
  }, {});
};

/**
 * Returns object except fields from object
 * @param {Object} object
 * @param {string[]} fields
 */
export const extractExceptFields = (object, fields) => {
  const newObject = { ...object };
  fields.forEach((field) => {
    delete newObject[field];
  });
  return newObject;
};

export const getFullName = (user) => {
  if (!user) return "";
  if (!user?.lastName) return `${user?.firstName} ${user?.middleName}`;
  if (!user?.middleName) return `${user?.lastName} ${user?.firstName}`;
  return `${user?.lastName} ${user?.firstName} ${user?.middleName}`;
};

export const getFullAdress = (building) => {
  if (!building) return "";
  return `${building?.city}, ${building?.district}, ${building?.street}, ${building?.houseNumber}`;
};

export const getKey = (obj, value) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const formatDate = (value) => {
  return moment(value).format("YYYY-MM-DD");
};

export const formatDateFrom = (value, noTime = false) => {
  if (!value) return "";
  return moment
    .utc(value)
    .local()
    .format(noTime ? "LL" : "LLL");
};

export const getFilesFormData = (files) => {
  if (!files) return null;

  const toServerFormData = new FormData();

  files.forEach((file) => {
    toServerFormData.append("files", file);
  });

  return toServerFormData;
};

export const singFileForFormularOrder = (file, type, id = "") => {
  if (!file) return null;

  const toServerFormData = new FormData();

  // const fileObj = new File([file], "payment-document");

  toServerFormData.append("file", file[0]);

  return toServerFormData;
};

export const checkAdminRole = (roles) => {
  // if (!Array.isArray(roles)) return false;
  return roles.includes("ROLE_ADMIN");
};

export const checkActiveRelease = (release) => {
  return release.status === "TOKENIZATION" ? release : null;
};

export const getActiveReleases = (releases) => {
  return releases.filter((release) => release.status === "TOKENIZATION");
};

export const getOldestRelease = (releases) => {
  if (!releases || releases.length === 0) return null;
  const dates = releases.map((release) => moment(release.plannedStartDate));

  const oldest = dates.reduce((prev, current) => {
    return prev.isBefore(current) ? prev : current;
  });

  return releases.find(
    (release) => release.plannedStartDate === oldest.format("YYYY-MM-DD"),
  );
};

export const getOldestActiveRelease = (releases) => {
  if (!releases || releases.length === 0) return null;
  return getOldestRelease(getActiveReleases(releases));
};

export const formatTokensForDisplay = (tokens) => {
  if (!tokens) return 0;
  return tokens; // 100 ЦФА = 1 кв. метр
};

export const formatTokensForSending = (tokens) => {
  if (!tokens) return 0;
  return tokens; // 1 отправляемый токен = 1 токен ЦФА хаб
};

export const downloadFile = (file, fileName = "") => {
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
};

export const findElemForTemplateDetailsPayment = (
  arrayDataPayment,
  typeElem,
) => {
  let foundItem = "значение не найдено";
  if (arrayDataPayment && arrayDataPayment.length > 0) {
    arrayDataPayment.forEach((el) => {
      let elemSplit = el.split("=");
      if (elemSplit.includes(typeElem)) {
        foundItem = elemSplit[1];
      }
    });
  }
  return foundItem;
};

export const createSignatureCrypto = async (
  signedFile,
  setSignatureError,
  setHashError,
  setSignFile,
  thumbprint,
) => {
  let hash;

  setSignFile(null);
  setSignatureError("");
  setHashError(null);

  try {
    hash = await createHash(JSON.stringify(signedFile));
  } catch (error) {
    setHashError(error.message);
    return;
  }

  try {
    setSignFile(await createDetachedSignature(thumbprint, hash));
  } catch (error) {
    setSignatureError(error.message);
  }
};

export const getNewNotificationsFetchInterval = (
  dataNotifications,
  arrayNotificationsRef,
  key,
  countNewNotifications,
  setCountNewNotifications,
) => {
  if (arrayNotificationsRef.current.length === 0) {
    arrayNotificationsRef.current = dataNotifications.content;
  }

  const findDiffItems = dataNotifications.content.filter(
    (el1) =>
      !arrayNotificationsRef.current.some((el2) => el1[key] === el2[key]),
  );
  if (findDiffItems && findDiffItems.length > 0) {
    setCountNewNotifications(countNewNotifications + findDiffItems.length);
    arrayNotificationsRef.current = dataNotifications.content;
  }
};
