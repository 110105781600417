import classNames from "classnames";
import "./index.scss";
import { useFormContext } from "react-hook-form";
const Input = ({
  size = "default",
  placeholder = "",
  withLabel = false,
  withNotification = false,
  as,

  addClass,
  labelSize = "default",

  withError = false,
  disabled = false,
  required = false,
  name,
  ...arg
}) => {
  const formContext = useFormContext();

  const register = formContext ? formContext.register : (name) => name; // костыли потом убрать
  const { errors } = formContext ? formContext.formState : { errors: {} };
  const inputClass = classNames({
    input: true,
    [`input_${size}`]: true,
    [`input_${as}`]: true,
    [`${addClass}`]: addClass,
    [`input_disabled`]: disabled,
    [`input_error`]: errors[name],
  });

  const labelClass = classNames({
    "input-wrapper__label": true,
    "body-smaller": labelSize === "small",
  });

  return (
    <label className="input-wrapper">
      {withLabel && (
        <span className={labelClass}>
          {withLabel}
          {required && <span style={{ color: 'red' }}> *</span>}
        </span>
      )}

      {(() => {
        switch (as) {
          case "textarea":
            return (
              <textarea
                className={inputClass}
                placeholder={placeholder}
                disabled={disabled}
                {...register(name, { required })}
                {...arg}
              />
            );
          case "input":
          default:
            return (
              <input
                className={inputClass}
                placeholder={placeholder}
                disabled={disabled}
                {...register(name, { required })}
                {...arg}
              />
            );
        }
      })()}
      {errors[name] && (
        <span className="input-error">
          {errors[name].type === "required"
            ? "Обязательное поле*"
            : errors[name]?.message}
        </span>
      )}

      {withError && <span className="input-error">{withError}</span>}
    </label>
  );
};

export default Input;
